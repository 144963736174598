<template>
  <!-- 优惠劵配置 -->
  <div class="box-card">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="优惠劵名称：">
        <el-input v-model="queryForm.coupon_name" maxlength="30" @keyup.enter.native="onSubmit" placeholder="请输入优惠劵名称"
          @input="(e) => (queryForm.coupon_name = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item label="类型：">
        <el-select popper-class="my-select" clearable v-model="queryForm.coupon_type" filterable default-first-option
          placeholder="请选择优惠劵类型" @change="onSubmit()">
          <el-option v-for="item in getOptions('CouponType')" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="优惠劵配置">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('b6081168c07c48c48c8a0507')">删除
          </lbButton>
          <lbButton icon="xinzeng" @click="addDiscountCoupon()" v-if="this.getUserInfo.user_name != 'admin' &&
            isShowBtn('3c6cab6708c34ec592353789')">新增</lbButton>
        </div>
      </template>
      <!-- 主要内容 -->
      <div style="text-align: left">
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
          @size-change="sizeChange" @current-change="currentChange">
          <template slot-scope="{row}" slot="coupon_type">
            {{ getWayName("CouponType", row.coupon_type) }}
          </template>
          <template slot-scope="{row}" slot="parking_count">
            <el-link type="primary" target="_blank" v-if="row.parking_count > 0" @click="openDialog(row.parking_id)">{{ row.parking_count }}</el-link>
            <span v-else>{{ row.parking_count }}</span>
          </template>
          <template slot-scope="{row}" slot="create_time">
            {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditForm(row)"
              v-if="isShowBtn('2e444beb99a043db936cf433')"></lbButton>
          </template>
        </avue-crud> -->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
          <el-table-column prop="coupon_name" label="优惠劵名称" min-width="180"></el-table-column>
          <el-table-column label="类型">
            <template slot-scope="scope">
              {{ getWayName("CouponType", scope.row.coupon_type) }}
            </template>
          </el-table-column>
          <el-table-column label="在用停车场">
            <template slot-scope="scope">
              <el-link type="primary" target="_blank" v-if="scope.row.parking_count > 0"
                @click="openDialog(scope.row.parking_id)">{{ scope.row.parking_count }}</el-link>
              <span v-else>{{ scope.row.parking_count }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditForm(scope.row)"
                v-if="isShowBtn('2e444beb99a043db936cf433')"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </le-card>
    <!-- 在用停车场 -->
    <lebo-dialog append-to-body title="在用停车场" :isShow="detailsDialog" style="text-align: start" width="33%"
      @close="detailsDialog = false" footerSlot closeOnClickModal>
      <el-table :data="detailsData" border class="table_box" max-height="500" height="460">
        <el-table-column type="index" width="150" align="center" header-align="center" label="序号"></el-table-column>
        <el-table-column prop="parking_name" label="停车场"></el-table-column>
      </el-table>
    </lebo-dialog>
    <!-- 新增、编辑优惠劵 -->
    <lebo-dialog append-to-body :title="batchAddForm._id ? '编辑优惠劵' : '新增优惠劵'" :isShow="isBatchAddDialogVisible"
      style="text-align: start" width="33%" @close="closeBatchAddDialog" footerSlot>
      <el-form :model="batchAddForm" :rules="batchAddFormRules" ref="batchAddFormRef" label-width="120px"
        class="form_box">
        <el-form-item label="优惠劵名称：" prop="coupon_name">
          <el-input v-model="batchAddForm.coupon_name" placeholder="请输入优惠劵名称" maxlength="30"
            @input="(e) => (batchAddForm.coupon_name = validSpace(e))"></el-input>
        </el-form-item>
        <el-form-item label="类型：" prop="coupon_type">
          <el-select popper-class="my-select" v-model="batchAddForm.coupon_type" placeholder="请选择类型" style="width: 100%">
            <el-option v-for="item in getOptions('CouponType')" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="优惠金额：" prop="coupon_price" v-if="batchAddForm.coupon_type == 1">
          <el-input v-model="batchAddForm.coupon_price" placeholder="请输入优惠金额" maxlength="9"
            @input="(e) => (batchAddForm.coupon_price = validSpacing(e))"></el-input>
        </el-form-item>
        <el-form-item label="优惠小时数：" prop="coupon_hours" v-else-if="batchAddForm.coupon_type == 2">
          <el-input v-model="batchAddForm.coupon_hours" placeholder="请输入优惠小时数" maxlength="8"
            @input="(e) => (batchAddForm.coupon_hours = validNumberInteger(e))"></el-input>
        </el-form-item>
        <el-form-item label="天数限制：" prop="coupon_day" v-else>
          <el-input v-model="batchAddForm.coupon_day" placeholder="请输入天数限制" maxlength="8"
            @input="(e) => (batchAddForm.coupon_day = validNumberInteger(e))"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="closeBatchAddDialog">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="submitForm">保 存</lbButton>
      </div>
    </lebo-dialog>
  </div>
</template>
<script>
import {
  addCoupon,
  updateCoupon,
  delCoupon,
  getCouponPageList,
  getCouponParkingsList
} from '@/api/discountCoupon'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  data () {
    return {
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        coupon_name: '',
        coupon_type: null // 优惠券类型 1.金额券 2.小时券 3.优免券
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      // 在用停车场对话框
      detailsDialog: false,
      detailsData: [],
      // 新增、编辑优惠劵对话框
      isBatchAddDialogVisible: false,
      batchAddForm: {
        _id: '',
        coupon_name: '', // 优惠券名称
        coupon_type: 1, // 枚举 优惠券类型 1.金额券 2.小时券 3.优免券
        coupon_price: null, // 优惠券金额
        coupon_hours: null, // 优惠券时长
        coupon_day: null // 优惠券天数
      },
      batchAddFormRules: {
        coupon_name: [
          { required: true, message: '请输入优惠券名称', trigger: 'blur' }
        ],
        coupon_type: [
          { required: true, message: '请选择优惠券类型', trigger: 'blur' }
        ],
        coupon_price: [
          { required: true, message: '请输入优惠券金额', trigger: 'blur' },
          {
            pattern: /^([1-9]\d*|0)(\.\d{1,2})?$/,
            message: '请输入带两位小数的数字',
            trigger: 'blur'
          }
        ],
        coupon_hours: [
          { required: true, message: '请输入优惠券时长', trigger: 'blur' }
        ],
        coupon_day: [
          { required: true, message: '请输入优惠券天数', trigger: 'blur' }
        ]
      },
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '优惠劵名称',
            prop: 'coupon_name'
          }, {
            label: '类型',
            prop: 'coupon_type',
            slot: true
          }, {
            label: '在用停车场',
            prop: 'parking_count',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    this.fngetCouponPageList()
  },
  mounted () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters([
      'isAdmin',
      'getOptions',
      'getWayName',
      'getAllParkingList',
      'getUserInfo'
    ])
  },
  watch: {},
  methods: {
    async fngetCouponPageList () {
      const res = await getCouponPageList({
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        coupon_name: this.queryForm.coupon_name,
        coupon_type: this.queryForm.coupon_type
          ? this.queryForm.coupon_type
          : 0
      })
      this.tableData = res && res.Code === 200 && res.Data ? res.Data.DataList : []
      this.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fngetCouponPageList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fngetCouponPageList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fngetCouponPageList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.queryForm.PageIndex = val
      this.fngetCouponPageList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fngetCouponPageList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的优惠劵, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            var obj = {}
            var arr = []
            for (var i = 0; i < this.multipleSelection.length; i++) {
              arr.push(this.multipleSelection[i]._id)
              if (arr.length === this.multipleSelection.length) {
                obj._id = arr.join(',')
                this.fndelCoupon(obj)
              }
            }
          })
          .catch(() => {
            this.$msg.info('已取消删除')
          })
      } else {
        this.$msg.warning('请选择要删除的优惠劵！')
      }
    },
    async fndelCoupon (obj) {
      const res = await delCoupon(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fngetCouponPageList()
    },
    async openDialog (id) {
      const res = await getCouponParkingsList({ parking_id: id })
      if (res && res.Code === 200) {
        var arr = []
        res.Data.forEach((item) => arr.push({ parking_name: item }))
        this.detailsData = arr
        this.detailsDialog = true
      }
    },
    openEditForm (row) {
      var that = this
      this.isBatchAddDialogVisible = true
      this.$nextTick(() => {
        that.batchAddForm._id = row._id
        that.batchAddForm.coupon_name = row.coupon_name
        that.batchAddForm.coupon_type = row.coupon_type
        that.batchAddForm.coupon_price =
          row.coupon_type === 1 ? row.coupon_price : null
        that.batchAddForm.coupon_hours =
          row.coupon_type === 2 ? row.coupon_hours : null
        that.batchAddForm.coupon_day =
          row.coupon_type === 3 ? row.coupon_day : null
      })
    },
    submitForm () {
      // 对整个表单进行校验
      this.$refs.batchAddFormRef.validate((valid) => {
        // 成功
        if (valid) {
          var obj = {
            coupon_name: this.batchAddForm.coupon_name,
            coupon_type: this.batchAddForm.coupon_type
          }
          if (this.batchAddForm.coupon_type === 1) {
            obj.coupon_price = this.batchAddForm.coupon_price
          } else if (this.batchAddForm.coupon_type === 2) {
            obj.coupon_hours = this.batchAddForm.coupon_hours
          } else {
            obj.coupon_day = this.batchAddForm.coupon_day
          }
          if (this.batchAddForm._id) {
            obj._id = this.batchAddForm._id
            this.fnupdateCoupon(obj)
          } else {
            console.log(obj)
            this.fnaddCoupon(obj)
          }
        } else {
          return false
        }
      })
    },
    async fnaddCoupon (obj) {
      const res = await addCoupon(obj)
      if (res && res.Code === 200) {
        this.onSubmit()
        this.isBatchAddDialogVisible = false
      }
    },
    async fnupdateCoupon (obj) {
      const res = await updateCoupon(obj)
      if (res && res.Code === 200) {
        this.fngetCouponPageList()
        this.isBatchAddDialogVisible = false
      }
    },
    closeBatchAddDialog () {
      this.isBatchAddDialogVisible = false
      this.$refs.batchAddFormRef.resetFields()
      this.batchAddForm._id = ''
      console.log(this.batchAddForm)
    },
    // 新增优惠券
    addDiscountCoupon () {
      this.batchAddForm._id = ''
      this.batchAddForm.coupon_name = ''
      this.batchAddForm.coupon_type = ''
      this.batchAddForm.coupon_price = ''
      this.batchAddForm.coupon_hours = ''
      this.batchAddForm.coupon_day = ''
      this.isBatchAddDialogVisible = true
    },
    // 选中的数据
    selectionChange (list) {
      this.multipleSelection = list
    }
  }
}
</script>
<style scoped lang="less">
.box-card {
  margin-top: 20px;
}
</style>
